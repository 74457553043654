import React, { useLayoutEffect } from "react";
import { useState, useEffect } from "react";
import "./style.css";
// import Header from "./Header";
import Footer2 from "./Footer2";
// import UserformSurvey from "./mattress_questionnare";
import { Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import Recaptcha from "react-recaptcha";
import { Helmet } from "react-helmet";
import LogService from "../services/log.service";

// import axios from "axios";

function Index(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Register";
  const navigate = useNavigate();
  const [website, setWebsite] = useState(window.location.href);

  useLayoutEffect(() => {
    const destroy = () => {
      localStorage.clear();
    };
    destroy();
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    localStorage.setItem("isQuestionnare", "true");
    // props.history.push("/register/RegisterForm");
    navigate("/register/mattress-questionnare");
    // <--- The page you want to redirect your user to.
  };
  useEffect(() => {
    if (localStorage.getItem("return_page") === "true") {
      navigate("/register/mattress-questionnare");
    } else {
      localStorage.setItem("return_page", "false");
    }
  }, []);
  localStorage.setItem("return_page", "false");
  localStorage.setItem("TestOurTech_thankyou", "false");
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <Header /> */}
        <div className="white-box">
          <ul className="points">
            <form
              method="post"
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submitForm}
            >
              <h2 className="register_lower" style={{ textAlign: "center" }}>
                CITY Furniture and Elite Rewards are proud to support the
                American Cancer Society to make a life changing difference.
              </h2>
              <div className="row">
                <div className="col-md-12" id="text">
                  <p className="register-p">
                    {" "}
                    You Get $25, We Give $25. Would you be interested in giving
                    5-10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $25 reward
                    card to do a sleep survey in our mattress gallery and CITY
                    Furniture will make a $25 donation to the American Cancer
                    Society. It’s a ride to treatment, it helps fund lodging, it
                    means early detection, it’s an end to breast cancer. Just a
                    few moments of your time can help improve your sleep and
                    impact families in our community.
                  </p>
                </div>
              </div>
              <br />
              <input
                // onClick="myTimeout()"
                type="submit"
                name="submit"
                id="submit"
                value="CONTINUE"
                className="sub-btn button"
              ></input>
            </form>
          </ul>
        </div>
      </div>

      <Footer2 />
    </>
  );
}
export default Index;
