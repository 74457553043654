import { React, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import "./style.css";
import Footer2 from "./Footer2";
import { Navigate, useNavigate } from "react-router-dom";
// import Recaptcha from "react-recaptcha";
// import StorageService from "../services/storage.service";
import { Helmet } from "react-helmet";

const Mattress_questionnare = (props) => {
  const [ans1, setAns1] = useState(" ");
  const [ans2, setAns2] = useState(" ");
  const [ans3, setAns3] = useState(" ");
  const [ans4, setAns4] = useState(" ");
  const [ans5, setAns5] = useState(" ");
  const [ans6, setAns6] = useState(" ");
  const [ans7, setAns7] = useState(" ");
  const [ans8, setAns8] = useState(" ");
  const [ans9, setAns9] = useState(" ");
  const [ans10, setAns10] = useState(" ");
  const [ans11, setAns11] = useState(" ");
  const [ans12, setAns12] = useState(" ");
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(true);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const [website, setWebsite] = useState(window.location.href);
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Mattress Questionnaire";
  const navigate = useNavigate();

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", false);
    localStorage.removeItem("isQuestionnare");
    localStorage.removeItem("page");
    localStorage.removeItem("ans1");
    localStorage.removeItem("ans2");
    localStorage.removeItem("ans3");
    localStorage.removeItem("ans4");
    localStorage.removeItem("ans5");
    localStorage.removeItem("ans6");
    localStorage.removeItem("ans7");
    localStorage.removeItem("ans8");
    localStorage.removeItem("ans9");
    localStorage.removeItem("ans10");
    localStorage.removeItem("ans11");
    localStorage.removeItem("ans12");
    window.location.replace("/register");
  };

  const handleChangeans1 = (event) => {
    const ans1 = event.target.value;
    let { value, name } = event.target;
    if (name === "age" && value === "true") {
      value = " ";
    }
    setAns1(value);
    localStorage.setItem("ans1", value);
    console.log(ans1);
  };
  const handleChangeans2 = (event) => {
    const ans2 = event.target.value;
    let { value, name } = event.target;
    if (name === "hours" && value === "true") {
      value = " ";
    }
    setAns2(value);
    localStorage.setItem("ans2", value);
    console.log(ans2);
  };
  const handleChangeans3 = (event) => {
    const ans3 = event.target.value;
    let { value, name } = event.target;
    if (name === "quality" && value === "true") {
      value = " ";
    }
    setAns3(value);
    localStorage.setItem("ans3", value);
    console.log(ans3);
  };
  const handleChangeans4 = (event) => {
    const ans4 = event.target.value;
    let { value, name } = event.target;
    if (name === "rested" && value === "true") {
      value = " ";
    }
    setAns4(value);
    localStorage.setItem("ans4", value);
    console.log(ans4);
  };
  const handleChangeans5 = (event) => {
    const ans5 = event.target.value;
    let { value, name } = event.target;
    if (name === "pain" && value === "true") {
      value = " ";
    }
    setAns5(value);
    localStorage.setItem("ans5", value);
    console.log(ans5);
  };
  const handleChangeans6 = (event) => {
    const ans6 = event.target.value;
    let { value, name } = event.target;
    if (name === "size" && value === "true") {
      value = " ";
    }
    setAns6(value);
    localStorage.setItem("ans6", value);
    console.log(ans6);
  };
  const handleChangeans7 = (event) => {
    const ans7 = event.target.value;
    let { value, name } = event.target;
    if (name === "support" && value === "true") {
      value = " ";
    }
    setAns7(value);
    localStorage.setItem("ans7", value);
    console.log(ans7);
  };
  const handleChangeans8 = (event) => {
    const ans8 = event.target.value;
    let { value, name } = event.target;
    if (name === "sleeper" && value === "true") {
      value = " ";
    }
    setAns8(value);
    localStorage.setItem("ans8", value);
    console.log(ans8);
  };
  const handleChangeans9 = (event) => {
    const ans9 = event.target.value;
    let { value, name } = event.target;
    if (name === "pillow" && value === "true") {
      value = " ";
    }
    setAns9(value);
    localStorage.setItem("ans9", value);
    console.log(ans9);
  };
  const handleChangeans10 = (event) => {
    const ans10 = event.target.value;
    let { value, name } = event.target;
    if (name === "temperature" && value === "true") {
      value = " ";
    }
    setAns10(value);
    localStorage.setItem("ans10", value);
    console.log(ans10);
  };
  const handleChangeans11 = (event) => {
    const ans11 = event.target.value;
    let { value, name } = event.target;
    if (name === "bed_option" && value === "true") {
      value = " ";
    }
    setAns11(value);
    localStorage.setItem("ans11", value);
    console.log(ans11);
  };
  const handleChangeans12 = (event) => {
    const ans12 = event.target.value;
    let { value, name } = event.target;
    if (name === "bed_option" && value === "true") {
      value = " ";
    }
    setAns12(value);
    localStorage.setItem("ans12", value);
    console.log(ans12);
  };

  const verifyCallback = (response) => {
    let errors = {};
    if (response) {
      setCaptcha({
        captcha: true,
      });
    } else {
      setErrors(errors);
      return false;
    }
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      localStorage.setItem("isRegister", "true");
      if (captcha) {
        secureLocalStorage.setItem("mattress", {
          ans1: ans1,
          ans2: ans2,
          ans3: ans3,
          ans4: ans4,
          ans5: ans5,
          ans6: ans6,
          ans7: ans7,
          ans8: ans8,
          ans9: ans9,
          ans10: ans10,
          ans11: ans11,
          ans12: ans12,
        });

        // localStorage.setItem("returnpage", true);
        navigate("/register/registration-form");
      }
    } else {
      localStorage.setItem("isRegister", false);
      // StorageService.setFormStatus(false);
    }
  };

  const validate = () => {
    let errors = {};
    let formIsValid = true;

    if (ans1 == " ") {
      formIsValid = false;
      errors["ans1"] = "Please Select The Age Of Mattress";
      setErrors(errors);
      return false;
    }

    if (ans2 == " ") {
      formIsValid = false;
      errors["ans2"] = "Please Select Hours Of Sleep";
      setErrors(errors);
      return false;
    }
    if (ans3 == " ") {
      formIsValid = false;
      errors["ans3"] = "Please Select One Option";
      setErrors(errors);
      return false;
    }

    if (ans4 == " ") {
      formIsValid = false;
      errors["ans4"] = "Please Select One Option";
      setErrors(errors);
      return false;
    }
    if (ans5 == " ") {
      formIsValid = false;
      errors["ans5"] = "Please Select One Option";
      setErrors(errors);
      return false;
    }
    if (ans6 == " ") {
      formIsValid = false;
      errors["ans6"] = "Please Select Size Of Mattress";
      setErrors(errors);
      return false;
    }

    if (ans7 == " ") {
      formIsValid = false;
      errors["ans7"] = "Please Select Type Of Support";
      setErrors(errors);
      return false;
    }
    if (ans8 == " ") {
      formIsValid = false;
      errors["ans8"] = "Please Select Type Of Sleeper";
      setErrors(errors);
      return false;
    }

    if (ans9 == " ") {
      formIsValid = false;
      errors["ans9"] = "Please Select Age Of Pillow";
      setErrors(errors);
      return false;
    }
    if (ans10 == " ") {
      formIsValid = false;
      errors["ans10"] = "This Field Cannot Be Empty";
      setErrors(errors);
      return false;
    }
    if (ans11 == " ") {
      formIsValid = false;
      errors["ans11"] = "This Field Cannot Be Empty";
      setErrors(errors);
      return false;
    }
    if (ans12 == " ") {
      formIsValid = false;
      errors["ans12"] = "This Field Cannot Be Empty";
      setErrors(errors);
      return false;
    }

    if (!captcha) {
      formIsValid = false;
      errors["checkederrorcaptcha"] = "Captcha not verified";
      setErrors(errors);
      return false;
    }
    return formIsValid;
  };
  const onLoadRecaptcha = () => {};

  let valid = localStorage.getItem("isQuestionnare");
  // const registerForm = localStorage.getItem("registerForm");

  // if(valid === undefined || valid != "true") {
  //   return <Navigate to="/register/registration-form" />;
  // }
  if (valid === undefined || valid != "true") {
    return <Navigate to="/register/" />;
  }
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        <div className="header_lower">Mattress Questionnaire</div>
        <div className="white-box form" id="font-style">
          <form
            name="userinfo"
            className="form-outer register clearfix"
            id="userinfo"
            onSubmit={submituserRegistrationForm}
            method="post"
            action=""
          >
            {/* Survey section */}
            <h1>
              Call the customer on the recorded line and follow the following
              scripting/question prompts:
            </h1>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <label>
                  <b>1.When was the last time you changed your mattress?</b>{" "}
                  <span>*</span>
                </label>
                <div className="boxheading"></div>

                <select
                  id="age"
                  name="age"
                  onChange={handleChangeans1}
                  style={errors.ans1 ? { border: "1px solid red" } : null}
                >
                  <option value>Select Age</option>
                  <option value="Less than 5 years">Less than 5 years</option>
                  <option value="5-10 years">5-10 years</option>
                  <option value="10+ years">10+ years</option>
                </select>

                <label className="inline ml-2">
                  <div className="errorMsg">{errors.ans1}</div>
                  <span style={{ color: "red" }} id="satisfaction_msg"></span>
                </label>
              </div>

              <div className="col-md-6">
                <label>
                  <b>2. How many hours on average are you sleeping at night?</b>
                  <span>*</span>
                </label>
                <select
                  id="hours"
                  name="hours"
                  onChange={handleChangeans2}
                  style={
                    errors.ans2 && !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Hours</option>
                  <option value="5 or Less Hours">5 or Less Hours</option>
                  <option value="7-8 Hours">7-8 Hours</option>
                  <option value="8+ Hours">8+ Hours</option>
                </select>

                <label className="inline ml-2">
                  <div className="errorMsg">{errors.ans2}</div>
                  <span style={{ color: "red" }} id="mattress_msg"></span>
                </label>
              </div>

              <div className="clearfix"></div>

              <div className="col-md-6">
                <label>
                  <b>
                    3. Do you have trouble falling sleep, staying asleep, or
                    waking up at night?
                  </b>
                  <span>*</span>
                </label>
                <select
                  id="quality"
                  name="quality"
                  onChange={handleChangeans3}
                  style={
                    errors.ans3 && !errors.ans2 && !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Option</option>
                  <option value="Falling asleep">Falling asleep</option>
                  <option value="Staying asleep">Staying asleep</option>
                  <option value="Waking up">Waking up</option>
                  <option value="All of the above">All of the above</option>
                  <option value="None of the above">None of the above</option>
                </select>

                <label className="inline ml-2">
                  <div className="errorMsg">{errors.ans3}</div>
                  <span style={{ color: "red" }} id="experience_msg"></span>
                </label>
              </div>

              <div className="col-md-6">
                <label>
                  <b>4. Do you wake up feeling <br/>rested?</b>
                  <span>*</span>
                </label>
                <select
                  id="rested"
                  name="rested"
                  onChange={handleChangeans4}
                  style={
                    errors.ans4 && !errors.ans3 && !errors.ans2 && !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Option</option>
                  <option value="Well rested">Well rested</option>
                  <option value="Still tired">Still tired</option>
                </select>

                <label className="inline ml-2">
                  <div className="errorMsg">{errors.ans4}</div>
                  <span style={{ color: "red" }} id="stomach_msg"></span>
                </label>
              </div>

              <div className="clearfix"></div>

              <div className="col-md-6">
                <label>
                  <b>
                    5. When you wake up in the morning do you have any aches or
                    pains?
                  </b>
                  <span>*</span>
                </label>
                <select
                  id="pain"
                  name="pain"
                  onChange={handleChangeans5}
                  style={
                    errors.ans5 &&
                    !errors.ans4 &&
                    !errors.ans3 &&
                    !errors.ans2 &&
                    !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>

                <label className="inline ml-2">
                  <div className="errorMsg">{errors.ans5}</div>
                  <span style={{ color: "red" }} id="store_msg"></span>
                </label>
              </div>

              <div className="col-md-6">
                <label>
                  <b>6. What is the size of the mattress you sleep <br/>on now?</b>
                  <span>*</span>
                </label>
                <select
                  id="size"
                  name="size"
                  onChange={handleChangeans6}
                  style={
                    errors.ans6 &&
                    !errors.ans5 &&
                    !errors.ans4 &&
                    !errors.ans3 &&
                    !errors.ans2 &&
                    !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Size</option>
                  <option value="King">King</option>
                  <option value="Queen">Queen</option>
                  <option value="Full">Full</option>
                  <option value="Twin/Twin XL">Twin/Twin XL</option>
                </select>

                <div className="errorMsg">{errors.ans6}</div>
                <span style={{ color: "red" }} id="size"></span>
              </div>

              <div className="clearfix"></div>

              <div className="col-md-6">
                <label>
                  <b>7. What type of support is your mattress?</b>
                  <span>*</span>
                </label>
                <select
                  id="support"
                  name="support"
                  onChange={handleChangeans7}
                  style={
                    errors.ans7 &&
                    !errors.ans6 &&
                    !errors.ans5 &&
                    !errors.ans4 &&
                    !errors.ans3 &&
                    !errors.ans2 &&
                    !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Type</option>
                  <option value="Innerspring">Innerspring</option>
                  <option value="Memory Foam">Memory Foam</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="Other">Other</option>
                </select>

                <div className="errorMsg">{errors.ans7}</div>
                <span style={{ color: "red" }} id="support_msg"></span>
              </div>

              <div className="col-md-6">
                <label>
                  <b>8. Are you a side, back,sor stomach sleeper?</b>
                  <span>*</span>
                </label>
                <select
                  id="sleeper"
                  name="sleeper"
                  onChange={handleChangeans8}
                  style={
                    errors.ans8 &&
                    !errors.ans7 &&
                    !errors.ans6 &&
                    !errors.ans5 &&
                    !errors.ans4 &&
                    !errors.ans3 &&
                    !errors.ans2 &&
                    !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Type</option>
                  <option value="Side">Side</option>
                  <option value="Back">Back</option>
                  <option value="Stomach">Stomach</option>
                </select>

                <div className="errorMsg">{errors.ans8}</div>
                <span style={{ color: "red" }} id="sleeper_msg"></span>
              </div>

              <div className="clearfix"></div>

              <div className="col-md-6">
                <label>
                  <b>9. How long have you had your current pillow?</b>
                  <span>*</span>
                </label>
                <select
                  id="pillow"
                  name="pillow"
                  onChange={handleChangeans9}
                  style={
                    errors.ans9 &&
                    !errors.ans8 &&
                    !errors.ans7 &&
                    !errors.ans6 &&
                    !errors.ans5 &&
                    !errors.ans4 &&
                    !errors.ans3 &&
                    !errors.ans2 &&
                    !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Age</option>
                  <option value="Less than 6 months">Less than 6 months</option>
                  <option value="6 months to 1 Year">6 months to 1 Year</option>
                  <option value="1+ Years">1+ Years</option>
                </select>

                <div className="errorMsg">{errors.ans9}</div>
                <span style={{ color: "red" }} id="pillow_msg"></span>
              </div>

              <div className="col-md-6">
                <label>
                  <b>10. Are you a warm or a cool sleeper?</b>
                  <span>*</span>
                </label>
                <select
                  id="temperature"
                  name="temperature"
                  onChange={handleChangeans10}
                  style={
                    errors.ans10 &&
                    !errors.ans9 &&
                    !errors.ans8 &&
                    !errors.ans7 &&
                    !errors.ans6 &&
                    !errors.ans5 &&
                    !errors.ans4 &&
                    !errors.ans3 &&
                    !errors.ans2 &&
                    !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Option</option>
                  <option value="Warm">Warm</option>
                  <option value="Cool">Cool</option>
                </select>

                <div className="errorMsg">{errors.ans10}</div>
                <span style={{ color: "red" }} id="temperature_msg"></span>
              </div>

              <div className="clearfix"></div>

              <div className="col-md-6">
                <label>
                  <b>11. Do you like to watch TV, read, or work <br/>in bed?</b>
                  <span>*</span>
                </label>
                <select
                  id="bed_option"
                  name="bed_option"
                  onChange={handleChangeans11}
                  style={
                    errors.ans11 &&
                    !errors.ans10 &&
                    !errors.ans9 &&
                    !errors.ans8 &&
                    !errors.ans7 &&
                    !errors.ans6 &&
                    !errors.ans5 &&
                    !errors.ans4 &&
                    !errors.ans3 &&
                    !errors.ans2 &&
                    !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Option</option>
                  <option value="Watch TV">Watch TV</option>
                  <option value="Read">Read</option>
                  <option value="Work">Work</option>
                  <option value="None of the above">None of the above</option>
                </select>

                <div className="errorMsg">{errors.ans11}</div>
                <span style={{ color: "red" }} id="bed_option_msg"></span>
              </div>

              <div className="col-md-6">
                <label>
                  <b>
                    12. If you could change one thing about your mattress what
                    would it be?
                  </b>
                  <span>*</span>
                </label>
                <select
                  id="change"
                  name="change"
                  onChange={handleChangeans12}
                  style={
                    errors.ans12 &&
                    !errors.ans11 &&
                    !errors.ans10 &&
                    !errors.ans9 &&
                    !errors.ans8 &&
                    !errors.ans7 &&
                    !errors.ans6 &&
                    !errors.ans5 &&
                    !errors.ans4 &&
                    !errors.ans3 &&
                    !errors.ans2 &&
                    !errors.ans1
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value>Select Option</option>
                  <option value="Size">Size</option>
                  <option value="Comfort level (Softer/Firmer)">
                    Comfort level (Softer/Firmer)
                  </option>
                  <option value="Support type (Coil, Foam, Hybrid)">
                    Support type (Coil, Foam, Hybrid)
                  </option>
                  <option value="Lifestyle base (Head/Feet Up, Massage, Zero G)">
                    Lifestyle base (Head/Feet Up, Massage, Zero G)
                  </option>
                  <option value="Temperature regulation">
                    Temperature regulation
                  </option>
                  <option value="Other">Other</option>
                </select>

                <div className="errorMsg">{errors.ans12}</div>
                <span style={{ color: "red" }} id="change_msg"></span>
              </div>

              <br />
              <br />

              {/* <Recaptcha
                        sitekey= {process.env.REACT_APP_API_GOOGLESITEKEY}
                        render="explicit"
                        onloadCallback={this.onLoadRecaptcha}
                        verifyCallback={this.verifyCallback}
                      />
                      
                      <div className="errorMsg">
                        {this.state.errors.checkederrorcaptcha}
                      </div> */}

              <span style={{ color: "red" }} id="response_msg"></span>
              <br />
              {/* <!-- <input type="checkbox" id="terms" value="terms"> I agree to <a href="#myModal" data-toggle="modal" >term and conditions </a> <br>
			<span style="color:red"  id="terms_msg"></span> --> */}
            </div>
            <input
              type="submit"
              className="submit-btn questionnare"
              name="submit2"
              value="Next"
            />
          </form>
        </div>
      </div>
      <Footer2 />
    </>
  );
};
export default Mattress_questionnare;
