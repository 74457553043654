import React from "react";
function Footer() {
  return (
    <div className="footer">
      <p>
        © Copyright {new Date().getFullYear()}. All rights reserved.{" "}
        {process.env.REACT_APP_CARINDEXFORMTITLE}.
      </p>
    </div>
  );
}
export default Footer;
