import React from "react";
import cityGift from "../Images/city_gift.png";
function GiftBox() {
  return (
    <div className="row">
    <div className="col-md-1">
      <img
        src={cityGift}
        alt="city_gift.png"
      />
    </div>

    <div className="col-md-11" style={{ fontFamily: "Arial" }}>
    Once you receive and action your email from{" "}
        <a href="mailto:notification@prepaiddigitalsolutions.com">
          {process.env.REACT_APP_PREP}
        </a>{" "}
        or <br />
        <a href="mailto:no-reply@hawkmarketplace.com">
          {process.env.REACT_APP_MARKET_PLACE}
        </a>
        , you can use the reward card virtually or request a card to be mailed to you.
    </div>
  </div>
  );
}
export default GiftBox;
